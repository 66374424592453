// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-autorizadas-js": () => import("./../src/pages/autorizadas.js" /* webpackChunkName: "component---src-pages-autorizadas-js" */),
  "component---src-pages-autorizadas-cardal-js": () => import("./../src/pages/autorizadas/cardal.js" /* webpackChunkName: "component---src-pages-autorizadas-cardal-js" */),
  "component---src-pages-autorizadas-hydra-corona-js": () => import("./../src/pages/autorizadas/hydra-corona.js" /* webpackChunkName: "component---src-pages-autorizadas-hydra-corona-js" */),
  "component---src-pages-autorizadas-lorenzetti-js": () => import("./../src/pages/autorizadas/lorenzetti.js" /* webpackChunkName: "component---src-pages-autorizadas-lorenzetti-js" */),
  "component---src-pages-autorizadas-mondial-js": () => import("./../src/pages/autorizadas/mondial.js" /* webpackChunkName: "component---src-pages-autorizadas-mondial-js" */),
  "component---src-pages-autorizadas-philips-walita-js": () => import("./../src/pages/autorizadas/philips-walita.js" /* webpackChunkName: "component---src-pages-autorizadas-philips-walita-js" */),
  "component---src-pages-autorizadas-sintex-js": () => import("./../src/pages/autorizadas/sintex.js" /* webpackChunkName: "component---src-pages-autorizadas-sintex-js" */),
  "component---src-pages-contato-js": () => import("./../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-servicos-js": () => import("./../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-servicos-reparo-air-fryer-jsx": () => import("./../src/pages/servicos/reparo-air-fryer.jsx" /* webpackChunkName: "component---src-pages-servicos-reparo-air-fryer-jsx" */),
  "component---src-pages-servicos-reparo-aquecedor-jsx": () => import("./../src/pages/servicos/reparo-aquecedor.jsx" /* webpackChunkName: "component---src-pages-servicos-reparo-aquecedor-jsx" */),
  "component---src-pages-servicos-reparo-ventilador-jsx": () => import("./../src/pages/servicos/reparo-ventilador.jsx" /* webpackChunkName: "component---src-pages-servicos-reparo-ventilador-jsx" */),
  "component---src-pages-servicos-servico-a-domicilio-jsx": () => import("./../src/pages/servicos/servico-a-domicilio.jsx" /* webpackChunkName: "component---src-pages-servicos-servico-a-domicilio-jsx" */),
  "component---src-pages-sobre-js": () => import("./../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */)
}

