const React = require("react")
const netlifyIdentity = require("netlify-identity-widget")
require("lazysizes")

const { config } = require("@fortawesome/fontawesome-svg-core")
require("@fortawesome/fontawesome-svg-core/styles.css")

config.autoAddCss = false

window.netlifyIdentity = netlifyIdentity

netlifyIdentity.init()

exports.wrapRootElement = ({ element }) => <>{element}</>
